<!-- eslint-disable -->
<template>
  <Loader v-if="!pageData" />
  <div v-else class="wrapper">
    <div class="flex justify-between items-center my-1">
      <h1 class="text-center text-base font-bold text-[#201E1ECC]">
        Deliveries
      </h1>
      <button
        @click="refreshPageData"
        class="flex items-center space-x-4 shadow border p-2 rounded"
      >
        <span>
          <img src="@/assets/Svg/refresh.svg" alt="" class="w-6 h-6" />
        </span>
        <span>Refresh</span>
      </button>
    </div>
    <div class="max-w-3xl mx-auto grid grid-cols-3 mt-6">
      <button
        class="tabbtn text-xs md:text-base md:py-3 py-2 w-full rounded-tl-md"
        :class="
          tab1
            ? ' bg-white  border-b-4  border-j2g-core  text-j2g-core'
            : ' non-activeTab  text-[#201E1E73]'
        "
        @click="
          () => {
            handleTab('tab1');
          }
        "
      >
        Pickup ({{ pickupDeliveries.length }})
      </button>

      <button
        class="tabbtn text-xs md:text-base md:py-3 py-2 w-full rounded-tr-md"
        :class="
          tab2
            ? ' bg-white  border-b-4  border-j2g-core  text-j2g-core'
            : ' non-activeTab  text-[#201E1E73]'
        "
        @click="
          () => {
            handleTab('tab2');
          }
        "
      >
        Dropoff ({{ dropoffDeliveries.length }})
      </button>
      <button
        class="tabbtn text-xs md:text-base md:py-3 py-2 w-full rounded-tr-md"
        :class="
          tab3
            ? ' bg-white  border-b-4  border-j2g-core  text-j2g-core'
            : ' non-activeTab  text-[#201E1E73]'
        "
        @click="
          () => {
            handleTab('tab3');
          }
        "
      >
        History ({{ parcels.length }})
      </button>
    </div>

    <div v-if="tab1">
      <div
        class="py-4 rounded-t grid grid-cols-2 text-gray-500 bg-[#ED32371A] font-medium items-center gap-4 mt-5 px-3"
      >
        <div class="text-sm text-center">From</div>
        <div class="text-sm text-center">Details</div>
      </div>
      <div class="divide-y font-medium table-wrapper">
        <div v-if="pickupDeliveries.length > 0">
          <div
            v-for="item in pickupDeliveries"
            :key="item.id"
            class="grid grid-cols-2 items-center gap-4 mb-3 py-3 px-2"
          >
            <div class="text-xs truncate text-center">
              {{ item.local_pickup.sender_address }}
            </div>
            <div class="text-xs grid place-content-center">
              <router-link
                :to="`/delivery/pickup/${item.id}`"
                class="action_button px-2 py-1"
              >
                Details
              </router-link>
            </div>
          </div>
        </div>
        <div v-else>
          <h1 class="text-center text-red-500 text-sm font-medium py-10">
            No Pickup Item
          </h1>
        </div>
      </div>
    </div>
    <div v-if="tab2">
      <div
        class="py-4 rounded-t grid grid-cols-2 text-gray-500 bg-[#ED32371A] font-medium items-center gap-4 mt-5 px-3"
      >
        <div class="text-sm text-center">To</div>
        <div class="text-sm text-center">Details</div>
      </div>
      <div class="divide-y font-medium table-wrapper">
        <div v-if="dropoffDeliveries.length > 0">
          <div
            v-for="item in dropoffDeliveries"
            :key="item.id"
            class="grid grid-cols-2 items-center gap-4 mb-3 py-3 px-2"
          >
            <div class="text-xs truncate text-center">
              {{ item.local_pickup.receipient_address }}
            </div>
            <div class="text-xs grid place-content-center">
              <router-link
                :to="`/delivery/dropoff/${item.id}`"
                class="action_button px-2 py-1"
              >
                Details
              </router-link>
            </div>
          </div>
        </div>
        <div v-else>
          <h1 class="text-center text-red-500 text-sm font-medium py-10">
            No Dropoff Item
          </h1>
        </div>
      </div>
    </div>
    <div v-if="tab3">
      <div
        class="py-4 rounded-t grid grid-cols-3 text-gray-500 bg-[#ED32371A] font-medium items-center gap-4 mt-5 px-3"
      >
        <div class="text-sm">Date</div>
        <div class="text-sm ">Sender's Name</div>
        <div class="text-sm text-center">Status</div>
      </div>
      <div class="divide-y font-medium table-wrapper">
        <div v-if="parcels.length > 0">
          <div
            v-for="item in parcels"
            :key="item.id"
            class="grid grid-cols-3 items-center gap-4 mb-3 py-3 px-2"
          >
            <div class="text-xs truncate ">
              {{ item.local_pickup.created_at }}
            </div>
            <div class="text-xs grid truncate capitalize">
              {{ item.local_pickup.sender_name }}
            </div>
            <div class="text-xs grid place-content-center">
              <router-link
                :to="`/delivery/info/${item.id}`"
                :class="getStyle(item.status)"
                class=" font-bold outline-none border-[0.5px] rounded-[25px] capitalize py-2 px-4"
              >
                {{ item.status }}
              </router-link>
            </div>
          </div>
        </div>
        <div v-else>
          <h1 class="text-center text-red-500 text-sm font-medium py-10">
            No Item
          </h1>
        </div>
      </div>




    </div>
  </div>
</template>
<!-- eslint-disable -->

<script>
/*eslint-disable*/
import axios from "@/Utils/axios.config.js";
import Loader from "./UI/Loader.vue";

export default {
  name: "StatusComp",
  components: { Loader },
  data() {
    return {
      tab1: true,
      tab2: false,
      tab3: false,
      pageData: false,
      parcels: [],
    };
  },
  created() {
    this.getParcels();
  },
  computed: {
    pickupDeliveries() {
      return this.parcels.filter(
        (item) => item.delivery_type === "pickup" && item.status === "assigned"
      );
    },

    dropoffDeliveries() {
      return this.parcels.filter(
        (item) => item.delivery_type === "dropoff" && item.status === "assigned"
      );
    },
  },
  methods: {
    handleTab(data) {
      if (data == "tab1") {
        this.tab1 = true;
        this.tab2 = false;
        this.tab3 = false;
      } else if (data == "tab2") {
        this.tab2 = true;
        this.tab1 = false;
        this.tab3 = false;
      } else {
        this.tab3 = true;
        this.tab1 = false;
        this.tab2 = false;
      }
    },
    async getParcels() {
      const token = localStorage.getItem("token");
      const axiosConfig = {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      };
      await axios
        .get("pickup/local/rider/deliveries/", axiosConfig)
        .then((res) => {
          this.parcels = res.data.data;
          this.$store.dispatch("getDeliveries", res.data.data);
          this.pageData = true;
        });
    },
    refreshPageData() {
      this.pageData = false;
      this.getParcels();
    },
    getStyle(status) {
      if (status.toLowerCase() === "fullfilled") {
        return "fulfilled ";
      } else if (status.toLowerCase() === "re_assigned") {
        return "reassigned";
      } else {
        return "assigned";
      }
    },
  },
};
</script>

<style scoped>
.fulfilled {
  @apply bg-green-100 text-green-500  border-green-600;
}
.reassigned {
  @apply bg-red-100 text-red-500  border-red-600;
}
.assigned {
  @apply bg-yellow-100 text-yellow-500  border-yellow-600;
}

.wrapper {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  padding: 14px;
}
.non-activeTab {
  background: rgba(20, 21, 64, 0.05);
}
.container {
  background: #f7f7f7;
}
.tabbtn {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-wrapper {
  background: #ffffff;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}
.action_button {
  background: rgba(237, 50, 55, 0.2);
  border: 1px solid #ed3237;
  border-radius: 25px;
  color: #ed3237;
}
</style>
