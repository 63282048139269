/*eslint-disable*/
import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import StatusPage from "@/views/Status.vue";
import OrderPickup from "@/views/OrderPickup.vue";
import OrderDropoff from "@/views/OrderDropoff.vue";
import OrderInfo from "@/views/OrderInfo.vue";

Vue.use(VueRouter);

const routeGuard = (to, from, next) => {
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  if (
    to.matched.some((record) => record.meta.requiresRiderLogin) &&
    isLoggedIn === "true"
    //localStorage Can only store string
  ) {
    next();
  } else {
    next("/");
  }
};

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/delivery",
    name: "Status",
    component: StatusPage,
    meta: {
      requiresRiderLogin: true,
    },
    beforeEnter: routeGuard,
  },
  {
    path: "/delivery/pickup/:pickup",
    name: "OrderPickup",
    component: OrderPickup,
    meta: {
      requiresRiderLogin: true,
    },
    beforeEnter: routeGuard,
  },
  {
    path: "/delivery/dropoff/:dropoff",
    name: "OrderDropoff",
    component: OrderDropoff,
    meta: {
      requiresRiderLogin: true,
    },
    beforeEnter: routeGuard,
  },
  {
    path: "/delivery/info/:info",
    name: "OrderInfo",
    component: OrderInfo,
    meta: {
      requiresRiderLogin: true,
    },
    beforeEnter: routeGuard,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
