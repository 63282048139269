<!-- eslint-disable -->
<template>
  <section >
    <header class="flex justify-center p-2 header">
      <img src="@/assets/Svg/logo.svg" alt="logo" />
    </header>
 
  </section>
</template>
<!-- eslint-disable -->

<script>
export default {};
</script>
<!-- eslint-disable -->

<style scoped>
.header {
  background: #fff;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
}
</style>
