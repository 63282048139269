<!-- eslint-disable -->
<template>
  <section>
    <header class="flex justify-between p-4 header">
      <button @click="isNavOpen = !isNavOpen">
        <img src="@/assets/Svg/hamburger.svg" alt="logo" />
      </button>
      <router-link to="/">
        <img src="@/assets/Svg/logo.svg" alt="logo" />
      </router-link>
      <div></div>
    </header>

    <div
      class="navabr px-4 pt-4 pb-20 flex flex-col transform transform-translate duration-300"
      :class="isNavOpen ? 'translate-x-0' : '-translate-x-[10000px]'"
    >
      <div class="flex justify-between">
        <router-link to="/">
          <img src="@/assets/Svg/logo.svg" alt="logo" />
        </router-link>
        <button @click="isNavOpen = !isNavOpen">
          <img src="@/assets/Svg/cancel.svg" alt="logo" />
        </button>
      </div>

      <div class="flex flex-col space-y-8 mt-5 px-3">
        <div><router-link to="/deliveries">Deliveries</router-link></div>
        <div><button @click="logout">Logout</button></div>
      </div>
    </div>
  </section>
</template>
<!-- eslint-disable -->

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      isNavOpen: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("getDeliveries", null);
      localStorage.removeItem("token")
      localStorage.removeItem("isLoggedIn")
      this.$router.push('/')
    },
  },
};
</script>
<!-- eslint-disable -->

<style scoped>
.header {
  background: #fff;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
}
.navabr {
  background: #fff;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 2;
}
</style>
