<!-- eslint-disable -->
<template>
  <section>
    <Loader v-if="loading" />

    <div class="container md:max-w-xl mx-auto">
      <h3 data-test="header" class="text-center">Login to your account</h3>
      <p class="sub-text">Please enter your credentials below.</p>
      <form data-test="form">
        <div class="mb-6 email flex flex-col space-y-2">
          <input
            style="background: rgba(161, 160, 160, 0.2)"
            id="email"
            v-model.trim="email"
            type="email"
            class="form-control form_email"
            placeholder="Email Address"
          />
          <small
            v-if="!$v.email.required && $v.email.$dirty"
            class="text-j2g-core px-2"
            >Email is required!</small
          >
          <small v-if="!$v.email.email" class="text-j2g-core px-2"
            >Invalid email address
          </small>
        </div>
        <div class="mb-6 input-group password">
          <div class="input-group-prepend">
            <input
              id="password"
              v-model.trim="password"
              :type="type"
              class="form-control bg-transparent"
              placeholder="Password"
              required
            />

            <div
              class="text-blue-500 text-xs font-medium icon cursor-pointer"
              @click="showPassword"
            >
              <span v-if="showPasswordtoggle">HIDE</span>
              <span v-else>SHOW</span>
            </div>
          </div>
        </div>
        <div class="errors w-full">
          <small
            v-if="!$v.password.required && $v.password.$dirty"
            class="text-j2g-core px-2"
          >
            Password is required!
          </small>
          <small
            v-if="!$v.password.minLength"
            class="text-left text-j2g-core px-2"
          >
            Password must be between 8 characters and above
          </small>
        </div>

        <button type="submit" class="mt-3 submit" @click.prevent="submitForm">
          Log in
        </button>
        <div class="mt-5 ml-auto">
          <a href="/forgotpassword" class="text-blue-400"
            >I have forgotten my password?</a
          >
        </div>
      </form>
    </div>
  </section>
</template>

<script>
/*eslint-disable*/
import { validationMixin } from "vuelidate";
import axios from "@/Utils/axios.config.js";
import { required, minLength, email } from "vuelidate/lib/validators";
import Loader from "./UI/Loader.vue";

export default {
  name: "login",
  mixins: [validationMixin],
  //data
  data() {
    return {
      type: "password",
      email: "",
      password: "",
      showPasswordtoggle: false,
      loading: false,
    };
  },
  //computed data
  //   computed: {
  //     ...mapGetters({
  //       loading: "authentication/loading",
  //     }),
  //   },
  //methods
  methods: {
    async signIn() {
      this.loading = true;
      const credentials = {
        email: this.email,
        password: this.password,
      };

      axios
        .post("user/login/", credentials)
        .then((res) => {
          this.loading = false;
          this.$toast.success("Login Successful");
          localStorage.setItem("token", res.data.accessToken);
          localStorage.setItem("isLoggedIn", true);
          this.$router.replace("/delivery");
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.$toast.error("Incorrect login details");
        });
    },
    showPassword() {
      this.showPasswordtoggle = !this.showPasswordtoggle;
      if (this.type === "password") {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.signIn();
      }
    },
  },
  //Validations
  validations: {
    email: { required, email },
    password: {
      required,
      minLength: minLength(8),
    },
  },
  components: { Loader },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  h3 {
    margin-top: 2.5rem;
    margin-bottom: 1rem;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #201e1e;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    padding: 0 1rem;

    .email {
      width: 100%;
      display: flex;
      justify-content: center;
      border: none;
    }
    .form-control {
      width: 100%;
      height: 3rem;
      padding: 1rem;
      border: none;
      outline: none;
      // background: rgba(161, 160, 160, 0.2);
      box-sizing: border-box;
      border-radius: 4px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #000;
    }
    .input-group {
      display: flex;
      width: 100%;
      justify-content: center;
      background: rgba(161, 160, 160, 0.2);

      .input-group-prepend {
        display: flex;
        align-items: center;
        width: 100%;
        height: 3rem;
        border: none;
        box-sizing: border-box;
        border-radius: 4px;
        .icon {
          padding: 0 0.75rem;
        }
      }
      // .form-control {
      //   height: 100%;
      //   background: #fff;
      //   border: none;
      //   font-style: normal;
      //   font-weight: normal;
      //   font-size: 14px;
      //   line-height: 16px;
      //   color: #000;
      // }
    }
    button.submit {
      width: 100%;
      height: 48px;
      background: #ec3237;
      /* Shadow2 Black */

      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
      border-radius: 25px;
      outline: none;
      color: white;
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      text-decoration: none;
      font-size: 0.875rem;
      font-weight: 500;
      color: white;
    }
  }
}

.sub-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: rgba(32, 30, 30, 0.65);
}
.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.4);
  display: grid;
  place-content: center;
  pointer-events: none;

  .loading {
    border: 15px solid #eaf0f6;
    border-radius: 50%;
    border-top: 15px solid #a00;
    width: 100px;
    height: 100px;
    animation: spinner 3s linear infinite;
  }
}
.page-enter-active,
.page-leave-active {
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  transition-duration: 1000ms;
}
.page-enter,
.page-leave-to {
  opacity: 0;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
