/*eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);
// import Cookies from "js-cookie";

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    deliveries: [],
  },
  actions: {
    getDeliveries({ commit }, value) {
      commit("GET_DELIVERIES", value);
    },
  },
  mutations: {
    GET_DELIVERIES(state, value) {
      state.deliveries = value;
    },
  },
  getters: {
    deliveries(state) {
      return state.deliveries;
    },
  },
});
