<!-- eslint-disable -->
<template>
  <div>
    <app-header />
    <main class="pt-24">
      <order-info />
    </main>
  </div>
</template>
<!-- eslint-disable -->

<script>
import AppHeader from "../components/Navigations/AppHeader.vue";
import OrderInfo from "../components/OrderInfoPickup.vue";
export default {
  components: { AppHeader, OrderInfo },
  name: "Order",
 
 
};
</script>
<!-- eslint-disable -->

<style></style>
