<!-- eslint-disable -->
<template>
  <div class="wrapper">
    <div class="flex justify-between px-4 items-center">
      <button class="arrows" type="button" @click="$router.go(-1)">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="32" height="32" fill="white" />
          <path
            d="M3.46967 15.4697C3.17678 15.7626 3.17678 16.2374 3.46967 16.5303L8.24264 21.3033C8.53553 21.5962 9.01041 21.5962 9.3033 21.3033C9.59619 21.0104 9.59619 20.5355 9.3033 20.2426L5.06066 16L9.3033 11.7574C9.59619 11.4645 9.59619 10.9896 9.3033 10.6967C9.01041 10.4038 8.53553 10.4038 8.24264 10.6967L3.46967 15.4697ZM28 15.25L4 15.25V16.75L28 16.75V15.25Z"
            fill="#201E1E"
            fill-opacity="0.8"
          />
        </svg>
      </button>
      <h1 class="text-base font-bold text-[#201E1ECC]">Pickup Order</h1>
    </div>

    <div
      class="py-4 rounded-t flex justify-between text-gray-500 bg-[#ED32371A] font-medium items-center gap-4 mt-5 px-3"
    >
      <div class="col-span-5 text-sm">Order ID</div>
      <div class="col-span-5 text-sm">
        #{{ parcel.local_pickup.track_number }}
      </div>
    </div>
    <div class="font-medium table-wrapper p-4">
      <div class="mb-5 grid grid-cols-12 items-center gap-6">
        <div
          class="col-span-2 icon w-8 h-8 rounded-full bg-[#ed3236] grid place-content-center"
        >
          <img class="w-5 h-5" src="@/assets/Svg/location.svg" alt="" />
        </div>
        <div class="col-span-8 flex flex-col space-y-2">
          <h4 class="font-medium text-xs text-[#201E1E45]">Pickup Address</h4>
          <h1 class="text-sm">
            {{ parcel.local_pickup.sender_address }}
          </h1>
        </div>

        <div class="col-span-2">
          <button
            @click="handlePosition"
            class="font-bold text-[#6DA544] text-sm"
          >
            MAP
          </button>
        </div>
      </div>
      <div class="mb-5 grid grid-cols-12 items-center gap-6">
        <div
          class="col-span-2 icon w-8 h-8 rounded-full bg-[#6DA544] grid place-content-center"
        >
          <img class="w-4 h-4" src="@/assets/Svg/phone.svg" alt="" />
        </div>
        <div class="col-span-8 flex flex-col space-y-2">
          <h4 class="font-medium text-xs text-[#201E1E45]">Phone Number</h4>
          <a
            :href="`tel:${parcel.local_pickup.sender_phone}`"
            class="text-sm"
            >{{ parcel.local_pickup.sender_phone }}</a
          >
        </div>

        <div class="col-span-2">
          <a
            :href="`tel:${parcel.local_pickup.sender_phone}`"
            class="font-bold text-[#6DA544] text-sm"
            >CALL</a
          >
        </div>
      </div>

      <div class="flex justify-between mt-6">
        <div class="flex flex-col space-y-2">
          <h4 class="font-medium text-xs text-[#201E1E45]">Customer’s name</h4>
          <h1 class="text-sm uppercase">
            {{ parcel.local_pickup.sender_name }}
          </h1>
        </div>
        <div class="flex flex-col space-y-2">
          <h4 class="font-medium text-xs text-[#201E1E45]">Item category</h4>
          <h1 class="text-sm uppercase">
            {{ parcel.local_pickup.item_category }}
          </h1>
        </div>
      </div>
      <div class="flex flex-col space-y-2 mt-4">
        <h4 class="font-medium text-xs text-[#201E1E45]">Item description</h4>
        <h1 class="text-sm">{{ parcel.local_pickup.description }}</h1>
      </div>
      <div
      v-if="parcel.local_pickup.image !== 'Empty'"
        class="flex flex-col space-y-2 mt-4"
      >
        <h4 class="font-medium text-xs text-[#201E1E45]">Item Image</h4>
        <img
          :src="parcel.local_pickup.image"
          alt="percel Image"
          class="h-[300px] w-full"
        />
      </div>

      <div class="flex flex-col space-y-2 mt-4 pt-2 border-t-2">
        <h4 class="font-medium text-xs text-[#201E1E45]">Status</h4>
        <h1 class="text-sm text-blue-500 uppercase">
          Rider {{ parcel.status }}
        </h1>
      </div>
      <!-- 
      <div
        @click="statusListIsOpen = !statusListIsOpen"
        class="dropdown flex justify-between items-center description w-full my-4 p-2 font-medium text-sm cursor-pointer relative"
      >
        <input
          type="text"
          required
          placeholder="Update status "
          class="outline-none w-full bg-transparent"
          v-model="selectedStatus.name"
        />

        <img
          src="@/assets/Svg/dropdown.svg"
          :class="statusListIsOpen ? 'rotate-180' : 'rotate-0'"
          class="transform transform-translate duration-500 h-6 w-6"
          alt=""
        />

        <div
          v-if="statusListIsOpen"
          class="absolute z-10 bg-white rounded top-[40px] inset-x-0 max-h-[200px] scrollbar-thin overflow-y-auto overflow-x-hidden flex flex-col"
        >
          <div
            v-for="(item, idx) in filteredStatusList"
            :key="idx"
            @click="handlestatusSelection(item)"
            class="p-4 mt-1 bg-gray-100 hover:bg-gray-300"
          >
            {{ item.name }}
          </div>
        </div>
      </div> -->
      <button
        @click="updateStatus"
        :disabled="update"
        :class="update ? 'cursor-not-allowed' : null"
        class="text-white bg-[#ed3236] rounded-3xl w-full py-2 mt-4"
      >
        <span v-if="update">Updating</span>
        <span v-else> Update status</span>
      </button>
    </div>
  </div>
</template>
<!-- eslint-disable -->

<script>
/*eslint-disable*/
import axios from "axios";
import axiosInstance from "@/Utils/axios.config.js";

import { mapGetters } from "vuex";
export default {
  name: "OrderInfo",
  data() {
    return {
      tab1: true,
      tab2: false,
      update: false,
      lat: "",
      lng: "",
      driverLocation: "",
      statusListIsOpen: false,
      selectedStatus: {
        name: "",
      },
      // availableStatus: [
      //   { name: "Picked Up" },
      //   { name: "Dropped Off" },
      //   { name: "Delivered" },
      // ],
    };
  },

  computed: {
    ...mapGetters(["deliveries"]),
    parcel() {
      return this.deliveries.find(
        (item) => item.id === parseInt(this.$route.params.pickup)
      );
    },
    // filteredStatusList() {
    //   return this.availableStatus.filter((item) =>
    //     item.name.toLowerCase().includes(this.selectedStatus.name)
    //   );
    // },
  },

  methods: {
    handleTab(data) {
      if (data == "tab1") {
        this.tab1 = true;
        this.tab2 = false;
      } else {
        this.tab1 = false;
        this.tab2 = true;
      }
    },
    // handlestatusSelection(item) {
    //   this.selectedStatus.name = item.name;
    //   if (this.statusListIsOpen) {
    //     this.statusListIsOpen = false;
    //   }
    // },
    getAddress(lat, lng) {
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCbj6Eha7O4apz9M4dDma1iJ0OwaOc6Bcg`
        )
        .then((res) => {
          if (res.data.error_message) {
            console.log(res.data.error_message);
          } else {
            console.log(res.data.results[0].formatted_address);
            this.driverLocation = res.data.results[0].formatted_address;
            const modifiedDriverLocation = this.driverLocation.replace(
              / /g,
              "+"
            );
            const modifiedSenderLocation =
              this.parcel.local_pickup.sender_address.replace(/ /g, "+");
            window.location.href = `https://www.google.com/maps/dir/${modifiedDriverLocation}/${modifiedSenderLocation}/`;
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
    handlePosition() {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position.coords);
        this.lng = position.coords.longitude;
        this.lat = position.coords.latitude;
        this.getAddress(position.coords.latitude, position.coords.longitude);
      });
    },
    updateStatus() {
      this.update = true;

      const token = localStorage.getItem("token");
      const axiosConfig = {
        headers: {
          Authorization: `Bearer  ${token}`,
        },
      };
      axiosInstance
        .post(
          "pickup/local/rider/update/status/pickup",
          { pickup_assign_id: this.parcel.id },
          axiosConfig
        )
        .then((res) => {
          this.update = false;
          this.$toast.success("Update Successful");
          this.$router.push("/delivery");
        });
    },
  },
};
</script>

<style scoped>
.wrapper {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  padding: 14px;
}
.non-activeTab {
  background: rgba(20, 21, 64, 0.05);
}
.dropdown {
  background: rgba(20, 21, 64, 0.05);
  border-radius: 3px;
}
.tabbtn {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-wrapper {
  background: #ffffff;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}
.action_button {
  background: rgba(237, 50, 55, 0.2);
  border: 1px solid #ed3237;
  border-radius: 25px;
  color: #ed3237;
}
</style>
