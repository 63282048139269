<!-- eslint-disable -->
<template>
  <div>
    <app-header />
    <main class="pt-24">
      <status-comp />
    </main>
  </div>
</template>
<!-- eslint-disable -->

<script>
import AppHeader from "../components/Navigations/AppHeader.vue";
import StatusComp from "../components/StatusComp.vue";
export default {
  components: { AppHeader, StatusComp },
};
</script>
<!-- eslint-disable -->

<style></style>
