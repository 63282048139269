/*eslint-disable*/

import axios from "axios";
const API_HOST_LOCAL = "http://127.0.0.1:8000/api/";
const API_HOST_DEV = "https://deliveries.africa/api/";

const instance = axios.create({
  baseURL: API_HOST_DEV,
});
export default instance;
