<!-- eslint-disable -->
<template>
  <div>
    <login-header />
    <main class="pt-24">
      <login-form />
    </main>
  </div>
</template>
<!-- eslint-disable -->

<script>
import LoginForm from "../components/LoginForm.vue";
import LoginHeader from "../components/Navigations/LoginHeader.vue";
export default {
  components: { LoginHeader, LoginForm },
};
</script>
<!-- eslint-disable -->

<style></style>
