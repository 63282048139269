import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import "./assets/Style/tailwind.css";

import router from "./router";
import store from "./store";



import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
  position: "top-center",
};


Vue.use(Toast, options);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
